// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photography-portraits-js": () => import("./../../../src/pages/photography/portraits.js" /* webpackChunkName: "component---src-pages-photography-portraits-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-audio-js": () => import("./../../../src/templates/audio.js" /* webpackChunkName: "component---src-templates-audio-js" */),
  "component---src-templates-code-js": () => import("./../../../src/templates/code.js" /* webpackChunkName: "component---src-templates-code-js" */),
  "component---src-templates-design-js": () => import("./../../../src/templates/design.js" /* webpackChunkName: "component---src-templates-design-js" */),
  "component---src-templates-discipline-type-js": () => import("./../../../src/templates/discipline-type.js" /* webpackChunkName: "component---src-templates-discipline-type-js" */),
  "component---src-templates-photography-js": () => import("./../../../src/templates/photography.js" /* webpackChunkName: "component---src-templates-photography-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-writing-js": () => import("./../../../src/templates/writing.js" /* webpackChunkName: "component---src-templates-writing-js" */)
}

